<template>
  <div>
    <!--Breadcrumb-->
    <section>
      <Breadcrumb
        :title="serviceGet.experience_name"
        titleType="H1"
        :array="[
          { islink: true, url: '/', text: 'Ana Sayfa' },
          { islink: true, url: '/experiences', text: 'Terapi Listesi' },
          { islink: false, url: '', text: experience_name },
        ]"
      />
    </section>
    <!--/Breadcrumb-->

    <!--Add listing-->
    <section class="sptb">
      <div class="container">
        <div class="row">
          <div class="col-xl-8 col-lg-8 col-md-12">
            <div class="card mb-4" v-if="isLoad">
              <div class="card-body">
                <h1 class="skeleton w-100 p-4 mb-4" style="height: 500px"></h1>
                <h4 class="skeleton w-70 leading-normal p-3"></h4>
                <div v-for="item in 2" :key="'loadContent' + item">
                  <div class="skeleton w-30 mt-2 p-2"></div>
                  <div class="skeleton w-50 mt-2 p-2"></div>
                  <div class="skeleton w-20 mt-2 p-2"></div>
                  <div class="skeleton w-60 mt-2 p-2"></div>
                  <div class="skeleton w-70 mt-2 p-2"></div>
                  <div class="skeleton w-10 mt-2 p-2"></div>
                  <div class="skeleton w-80 mt-2 p-2"></div>
                  <div class="skeleton w-50 mt-2 p-2"></div>
                  <div class="skeleton w-70 mt-2 p-2"></div>
                  <div class="skeleton w-10 mt-2 p-2"></div>
                </div>
              </div>
            </div>
            <div v-if="!isLoad" class="card">
              <div class="card-body">
                <div class="item7-card-img mb-2">
                  <lazy-image
                    :src="
                      'https://storage.terapivitrini.com/' + serviceGet.normal
                    "
                    :alt="serviceGet.experience_name"
                    class="w-100"
                  />
                </div>
                <div v-html="serviceGet.experience_description"></div>
                <router-link
                  :to="'/experiences/' + serviceGet.short_name"
                  class="btn btn-primary mt-5 w-100"
                  >{{ serviceGet.experience_name }}deki Terapistler</router-link
                >
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-12">
            <Item1 :item="serviceGet.ads" />
            <SurveyInfo :item="serviceGet.survey" />
            <div class="card" v-if="users.length > 0">
              <div class="card-header">İlgili Terapistler</div>
              <ProfileItem class="mt-2" :list="users" />
            </div>

            <Experience isDetail="true" />
          </div>
        </div>
      </div>
    </section>
    <!--/Add listing-->
  </div>
</template>
  <script>
import ProfileItem from "./SearchPages/Components/SlidableProfileItem.vue";
import Breadcrumb from "../components/Breadcrumb.vue";
import Item1 from "../components/Ads/Item1.vue";
import SurveyInfo from "../components/SurveyInfo.vue";
import Experience from "./SearchPages/Components/Experience.vue";
export default {
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: this.description,
        },
        {
          name: "author",
          content: this.author,
        },
        {
          name: "keywords",
          content: this.keywords,
        },
        { property: "og:title", content: this.title },
        { property: "og:description", content: this.description },
        { property: "og:image", content: this.image },
      ],
    };
  },
  created() {
    this.$store.state.header = true;
    this.$store.state.footer = true;
    let experienceInfo = {
      short_name: this.$route.params.search,
    };
    this.$store.dispatch("serviceList", experienceInfo).then((value) => {
      this.users = value[0].users;
      this.serviceGet = value[0];
      this.title =value[0].experience_name+" - Terapi Vitrini";
      this.experience_name =value[0].experience_name;
      this.author = value[0].fullname;
      this.description =
        value[0].experience_name +
        " Nedir? Hangi hastalıklara uygulanır? Tüm terapi alanları ve tüm görüşme yöntemleri terapivitrini.com da.";
      this.image =
        "https://storage.terapivitrini.com/" + this.serviceGet.thumbnail;
      this.isLoad = false;
      let _this=this;
      $(document).ready(function () {
        let content = document.getElementById("appcontent").innerHTML;
        _this.$store.dispatch("setMetaContent", [
          _this.title,
          _this.description,
          _this.author,
          _this.keywords,
          _this.image,
          content,
        ]);
      });
    });
  },
  data() {
    return {
      serviceGet: [],
      categoryList: [],
      isCategoryLoad: true,
      isLoad: true,
      users: [],
      title: "",
      experience_name:"",
      description: "",
      author: "",
      keywords: "",
      image: "",
    };
  },
  methods: {
    backToTop() {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        0
      );
      return false;
    },
  },
  components: { ProfileItem, Breadcrumb, Item1, Experience, SurveyInfo },
  mounted() {
    this.backToTop();
  },
};
</script>
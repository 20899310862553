<template>
  <div class="item2-gl">
    <div class="row">
      <div class="">
        <div class="row">
          <div id="myCarousel" class="owl-carousel testimonial-owl-carousel">
            <div
              class="col-lg-12 col-md-12 col-xl-12"
              v-for="(item, i) in list"
              :key="'user' + i"
            >
              <div class="overflow-hidden">
                <div class="item-card9-imgs">
                  <router-link
                    :to="'/terapist/' + item.shortlink"
                  ></router-link>
                  <lazy-image
                    :src="
                      'https://storage.terapivitrini.com/' + item.profile_image_url
                    "
                    alt="img"
                    class="w-100"
                    style="width: 100%; height:100%;"
                  />
                </div>
                <div class="card-body" style="min-height: 170.5px;">
                  <div class="item-card2">
                    <div class="item-card2-desc text-center">
                      <div class="item-card2-text">
                        <router-link
                          :to="'/terapist/' + item.shortlink"
                          class="text-dark"
                          ><h2
                            class="font-weight-bold mb-1"
                            style="font-size: 20px"
                          >
                            {{ item.fullname }}
                          </h2></router-link
                        >
                        <StarPos :pos="Math.ceil(item.total_score / item.total_comment)" :id="item.id" />
                      </div>
                      <p class="fs-16">{{ item.expert }}</p>
                      <router-link
                        :to="'/terapist/' + item.shortlink"
                        class="btn btn-white btn-sm"
                        >Profil Detayı</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
              <template v-if="i == list.length - 1 ? loadEd() : ''"></template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from "../../ProfileDetail/Components/Sidebar.vue";
import StarPos from "../../../components/StarPos.vue";
export default {
  props: ["list", "isLoad"],
  components: {
    Sidebar,
    StarPos,
  },

  methods: {
    loadEd() {
      setTimeout(() => {
        (function ($) {
          var owl = $(".owl-carousel-icons");
          owl.owlCarousel({
            margin: 25,
            loop: true,
            nav: true,
            autoplay: true,
            dots: true,
            responsive: {
              0: {
                items: 1,
              },
            },
          });
  
          // ______________Owl-carousel-icons2
          var owl = $(".owl-carousel-icons2");
          owl.owlCarousel({
            loop: true,
            rewind: false,
            margin: 25,
            animateIn: "fadeInDowm",
            animateOut: "fadeOutDown",
            autoplay: false,
            autoplayTimeout: 5000, // set value to change speed
            autoplayHoverPause: true,
            dots: false,
            nav: true,
            autoplay: true,
            responsiveClass: true,
            responsive: {
              0: {
                items: 1,
                nav: true,
              },
              600: {
                items: 2,
                nav: true,
              },
              1300: {
                items: 4,
                nav: true,
              },
            },
          });
  
          // ______________Owl-carousel-icons3
          var owl = $(".owl-carousel-icons3");
          owl.owlCarousel({
            margin: 25,
            loop: true,
            nav: false,
            dots: false,
            autoplay: true,
            responsive: {
              0: {
                items: 1,
              },
              600: {
                items: 2,
              },
              1000: {
                items: 2,
              },
            },
          });
  
          // ______________Owl-carousel-icons4
          var owl = $(".owl-carousel-icons4");
          owl.owlCarousel({
            margin: 25,
            loop: true,
            nav: false,
            dots: false,
            autoplay: true,
            responsive: {
              0: {
                items: 1,
              },
              600: {
                items: 3,
              },
              1000: {
                items: 6,
              },
            },
          });
  
          // ______________Owl-carousel-icons5
          var owl = $(".owl-carousel-icons5");
          owl.owlCarousel({
            loop: true,
            rewind: false,
            margin: 25,
            animateIn: "fadeInDowm",
            animateOut: "fadeOutDown",
            autoplay: false,
            autoplayTimeout: 5000, // set value to change speed
            autoplayHoverPause: true,
            dots: true,
            nav: false,
            autoplay: true,
            responsiveClass: true,
            responsive: {
              0: {
                items: 1,
                nav: true,
              },
              600: {
                items: 2,
                nav: true,
              },
              1300: {
                items: 4,
                nav: true,
              },
            },
          });
  
          // ______________Owl-carousel-icons6
          var owl = $(".owl-carousel-icons6");
          owl.owlCarousel({
            margin: 25,
            loop: true,
            nav: false,
            dots: false,
            autoplay: true,
            responsive: {
              0: {
                items: 1,
              },
              600: {
                items: 2,
              },
              1000: {
                items: 3,
              },
            },
          });
  
          // ______________Testimonial-owl-carousel2
          var owl = $(".testimonial-owl-carousel2");
          owl.owlCarousel({
            margin: 25,
            loop: true,
            nav: false,
            autoplay: true,
            dots: false,
            animateOut: "fadeOut",
            smartSpeed: 450,
            responsive: {
              0: {
                items: 1,
              },
            },
          });
  
          // ______________Testimonial-owl-carousel3
          var owl = $(".testimonial-owl-carousel3");
          owl.owlCarousel({
            margin: 25,
            loop: true,
            nav: false,
            autoplay: true,
            dots: true,
            responsive: {
              0: {
                items: 1,
              },
            },
          });
  
          // ______________Testimonial-owl-carousel4
          var owl = $(".testimonial-owl-carousel4");
          owl.owlCarousel({
            margin: 25,
            loop: true,
            nav: false,
            autoplay: true,
            dots: true,
            responsive: {
              0: {
                items: 1,
              },
            },
          });
  
          // ______________Testimonial-owl-carousel
          var owl = $(".testimonial-owl-carousel");
          owl.owlCarousel({
            loop: true,
            rewind: false,
            margin: 25,
            autoplay: true,
            animateIn: "fadeInDowm",
            animateOut: "fadeOutDown",
            autoplay: false,
            autoplayTimeout: 5000, // set value to change speed
            autoplayHoverPause: true,
            dots: false,
            nav: true,
            responsiveClass: true,
            responsive: {
              0: {
                items: 1,
                nav: true,
              },
            },
          });
        })(jQuery);
      }, 5);
    },
  },
};
</script>